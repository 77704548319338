<template>
  <b-row class="row">
    <div class="w-100">
      <ul style="list-style: none">
        <li>Changes will be applied to:</li>
        <li>1. Weekly credit overview email</li>
        <li>2. Export credit overview (PDF/Excel)</li>
      </ul>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <select class="form-control" v-model="overview">
        <option value="tvs">Dealer in Euro</option>
        <option value="local">Dealer in local currency</option>
      </select>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <button class="btn btn-primary" @click="createConfig">SAVE</button>
    </div>
  </b-row>
</template>

<script>
import {get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CreditOverview",
  mixins:[show_alert_mixin],
  mounted() {
    this.userId = this.$route.params.id
    this.loadData()
  },
  data: () => ({
    userId: null,
    overview: 'tvs',
    burns: true,
  }),
  methods: {
    async loadData() {
      const {data} = await get(`user/user-show-currency/${this.userId}`, null, true)
      this.burns = data.sendBurnsReports
      this.overview = data.creditOverview
    },
    async createConfig() {
      await post(`user/config-credit-overview`, {
        id: this.userId,
        overview: this.overview,
        burns: this.burns,
      }, true)
      this.showAlertNotification('Information updated')
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
