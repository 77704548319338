<template>
  <b-row>
    <b-col>
      <div class="tree-box box-border">
        <ul class="trees">
          <li class="has-child" v-for="(item) in MODULES_PLATFORM">
            <input id="tree-control1" type="checkbox" checked><span class="tree-control"></span>
            <label>
              <!--              <input type="checkbox" checked/>-->
              <i class="fa fa-list-ol mr-2"></i>{{ item.label }}
            </label>
            <ul>
              <li class="has-child" v-for="(child) in item.process">
                <input type="checkbox" checked><span class="tree-control"></span>
                <label v-if="parent === 'TVS'">
                  <input type="checkbox" v-model="child.status"/>
                  <i class="fa fa-tasks mr-2"></i> {{ child.label }}
                </label>
                <label v-else-if="parent !=='TVS' && item.value =='sale'">
                  <input type="checkbox" v-model="child.status"/>
                  <i class="fa fa-tasks mr-2"></i> {{ child.label }}
                </label>
              </li>
            </ul>
          </li>
        </ul>
        <button class="btn btn-primary mt-2" @click="changeStatus">UPDATE INFORMATION</button>
      </div>
    </b-col>
    <b-col>
      <span>
        <b>SALE CREDITS <br>Configuration invoice/Credit price list.</b>
        <br>
         If the option is unchecked. Related dealers will not be able to
        buy credits from the dealer
      </span>
    </b-col>
  </b-row>
</template>

<script>
import {get, post} from "@/services/api";
import {MODULES_PLATFORM, MODULES_PLATFORM_CHILDREN} from "@/constants";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "PermissionProcess",
  mixins: [show_alert_mixin],
  props: {
    children: {
      default: []
    },
    parent: {
      default: null
    },
    parentId: {
      default: null
    }
  },
  data: () => ({
    rules: null,
    userId: null,
    MODULES_PLATFORM: MODULES_PLATFORM()
  }),
  mounted() {
    this.userId = this.$route.params.id
    this.getData()
  },
  methods: {
    async getData() {
      const {data} = await get(`user-permissions/${this.userId}`, null, true)
      if (data !== null && data !== undefined && data !== "") {
        if (this.parent !== "TVS") {
          const canBuy = await this.getPermissionBuyCredits()
          data.process.forEach(item => {
            if (item.value === 'purchase') {
              item.process.forEach(value => {
                value.status = canBuy
              })
            }
          })
          this.MODULES_PLATFORM = data.process
        } else {
          this.MODULES_PLATFORM = data.process
        }
      }
    },

    async getPermissionBuyCredits() {
      const {data} = await get(`user-permissions/${this.parentId}`, null, true)
      if (data !== null && data !== undefined && data !== "") {
        const purchase = data.process.filter(x => x.value === "sale")
        if (purchase.length) {
          return purchase[0].process.find(x => x.value === "configuration").status
        }
      }
    },
    async changeStatus(value) {
      this.MODULES_PLATFORM.forEach(item => {
        if (item.value === 'purchase') {
          item.process.forEach(value => {
            item.status = value.status
          })
        }
      })
      await post("user-permissions/update", {
        userId: this.userId,
        process: this.MODULES_PLATFORM,
        children: this.children,
        default: MODULES_PLATFORM_CHILDREN()
      }, true)
      this.getData().then()
      this.showAlertNotification("Information updated")
    },
  }
}
</script>
<style scoped lang="scss">

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0
}

label {
  font-weight: normal
}

/*Tree*/

.trees {
  margin-left: 10px;
}

.trees li {
  border-left: dotted 1px #bcbec0;
  padding: 1px 0 1px 25px;
  position: relative
}

.trees li > label {
  position: relative;
  left: -11px
}

.trees li:before {
  content: "";
  width: 13px;
  height: 1px;
  border-bottom: dotted 1px #bcbec0;
  position: absolute;
  top: 10px;
  left: 0
}

.trees li:last-child:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 13px;
  background: #fff;
  left: -1px;
  bottom: 0px;
}

.trees li input {
  margin-right: 5px;
  margin-left: 5px
}

.trees li.has-child > ul {
  display: none
}

.trees li.has-child > input {
  opacity: 0;
  position: absolute;
  left: -14px;
  z-index: 9999;
  width: 22px;
  height: 22px;
  top: -5px
}

.trees li.has-child > input + .tree-control {
  position: absolute;
  left: -4px;
  top: 6px;
  width: 8px;
  height: 8px;
  line-height: 8px;
  z-index: 2;
  display: inline-block;
  color: #fff;
  border-radius: 3px;
}

.trees li.has-child > input + .tree-control:after {
  font-family: 'FontAwesome';
  content: "";
  font-size: 8px;
  color: #183955;
  position: absolute;
  left: 1px
}

.trees li.has-child > input:checked + .tree-control:after {
  font-family: 'FontAwesome';
  content: "";
  font-size: 8px;
  color: #183955;
  position: absolute;
  left: 1px
}

.trees li.has-child > input:checked ~ ul {
  display: block
}

.trees ul li.has-child:last-child {
  border-left: none
}

.trees ul li.has-child:nth-last-child(2):after {
  content: "";
  width: 1px;
  height: 5px;
  border-left: dotted 1px #bcbec0;
  position: absolute;
  bottom: -5px;
  left: -1px
}

.tree-alt li {
  padding: 4px 0
}
</style>
