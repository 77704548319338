<template >
  <div style="display: flex; justify-content: center">
  <h6>Coming soon</h6>
</div>
</template>

<script>
export default {
  name: "Agreement Documents",
  props: ['infoUser'],
  computed: {
    user() {
      return this.infoUser
    }
  },
  watch:{
    user:{
      immediate: true,
      handler(){}
    }
  }
}
</script>

<style scoped>

</style>
