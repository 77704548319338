import { render, staticRenderFns } from "./Obd2ipAccess.vue?vue&type=template&id=5d98dd86&scoped=true&"
import script from "./Obd2ipAccess.vue?vue&type=script&lang=js&"
export * from "./Obd2ipAccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d98dd86",
  null
  
)

export default component.exports