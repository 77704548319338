<template>
  <b-row v-if="requestUpdate !== null">
    <b-col>
      <table class="table table-bordered table-sm table-hover">
        <thead>
        <tr>
          <th colspan="2">Old information company user</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Name:</td>
          <td>{{ company.nameCompany }}</td>
        </tr>
        <tr>
          <td>Legal Representative:</td>
          <td>{{ company.legalRepresentative }}</td>
        </tr>
        <tr>
          <td>Registration Number:</td>
          <td>{{ company.numberCompany }}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{{ company.emailCompany }}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{{ company.phone }}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{{ company.address }}</td>
        </tr>
        <tr>
          <td>Address2:</td>
          <td>{{ company.address2 }}</td>
        </tr>
        <tr>
          <td>ZipCode:</td>
          <td>{{ company.zipCode }}</td>
        </tr>
        <tr>
          <td>Website:</td>
          <td>{{ company.website }}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td>{{ company.country }}</td>
        </tr>
        <tr>
          <td>City:</td>
          <td>{{ company.city }}</td>
        </tr>
        </tbody>
      </table>
    </b-col>
    <b-col>
      <table class="table table-bordered table-hover table-sm">
        <thead>
        <tr>
          <th colspan="2">New information company user</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Name:</td>
          <td>{{ requestUpdate.name }}</td>
        </tr>
        <tr>
          <td>Legal Representative:</td>
          <td>{{ requestUpdate.legalRepresentative }}</td>
        </tr>
        <tr>
          <td>Registration Number:</td>
          <td>{{ requestUpdate.registrationNumber }}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{{ requestUpdate.email }}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{{ requestUpdate.numberPhone }}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{{ requestUpdate.address }}</td>
        </tr>
        <tr>
          <td>Address2:</td>
          <td>{{ requestUpdate.address2 }}</td>
        </tr>
        <tr>
          <td>ZipCode:</td>
          <td>{{ requestUpdate.zipCode }}</td>
        </tr>
        <tr>
          <td>Website:</td>
          <td>{{ requestUpdate.website }}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td>{{ requestUpdate.country }}</td>
        </tr>
        <tr>
          <td>City:</td>
          <td>{{ requestUpdate.city }}</td>
        </tr>
        </tbody>
      </table>
      <button class="btn-primary btn-block" @click="acceptRequest()">ACCEPT REQUEST</button>
      <button class="btn-danger btn-block mt-2" @click="rejectedRequest()">REJECT REQUEST</button>
    </b-col>
  </b-row>
</template>

<script>
import {post} from "@/services/api";
import {userAcceptUpdateProfile} from "@/services/endpoint.json";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "Updaterequest",
  mixins: [show_alert_mixin],
  props: ['infoUpdate', 'infoCompany'],
  computed: {
    requestUpdate() {
      return this.infoUpdate
    },
    company() {
      return this.infoCompany
    }
  },
  watch: {
    requestUpdate: {
      immediate: true,
      handler(e) {
      }
    },
    company: {
      immediate: true,
      handler(e) {
      }
    }
  },
  data: () => ({}),
  methods: {
    async acceptRequest() {
      this.$swal({
        title: 'Are you sure?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(userAcceptUpdateProfile, {id: this.company.userId, companyId: this.company.id}, true)
          window.location.reload();
          this.showAlertNotification("'Process successful'")
        }
      });
    },
    rejectedRequest() {
      this.$swal({
        title: 'Are you sure?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("company/request-update-reject", {id: this.company.userId, companyId: this.company.id}, true)
          window.location.reload();
          this.showAlertNotification('Process successful')
        }
      });
    },
  }
}
</script>

<style scoped>
.heart {
  width: 100%;
  color: white;
  animation: beat 1s infinite alternate;
}

/* Heart beat animation */
@keyframes beat {
  to {
    box-shadow: 0 0 20px black;
  }
}

.selected {
  background: #dee2e6 !important;
  color: #d14343;
  font-weight: bold;
}
</style>
