<template>
  <div class="row" v-if="invoiceCustomInformation.companyName">
    <div class="col"></div>
    <div class="col">
      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.companyName.status"> Company name:
        </div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.companyName.value">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.attentionTo.status"> Attention to:
        </div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.attentionTo.value">
        </div>
      </div>

      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.address1.status"> Address 1</div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.address1.value">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.address2.status"> Address 2</div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.address2.value">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.city.status"> City</div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.city.value"></div>
      </div>
      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.zipCode.status"> Zip code</div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.zipCode.value"></div>
      </div>
      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.country.status"> Country</div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.country.value"></div>
      </div>
      <div class="row mt-2">
        <div class="col"><input type="checkbox" v-model="invoiceCustomInformation.vatNumber.status"> VAT number</div>
        <div class="col"><input class="form-control" type="text" v-model="invoiceCustomInformation.vatNumber.value">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <b-button variant="primary" class="btn-block" @click="assignCustomInformation"> UPDATE INFORMATION</b-button>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</template>

<script>
import {post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "Invoice Configuration",
  mixins: [show_alert_mixin],
  props: {
    invoiceCustomInformation: {
      default: {
        userId: null,
        companyName: {status: true, value: ''},
        vatNumber: {status: false, value: ''},
        attentionTo: {status: true, value: ''},
        address1: {status: true, value: ''},
        address2: {status: true, value: ''},
        city: {status: true, value: ''},
        zipCode: {status: true, value: ''},
        country: {status: true, value: ''}
      }
    }
  },
  methods: {
    async assignCustomInformation() {
      this.invoiceCustomInformation.userId = this.$route.params.id
      const {data} = await post("invoice-numeration/custom-information", this.invoiceCustomInformation, true)
      this.$emit('loadCompanyInformation')
      this.showAlertNotification('Information updated correctly')
    },
  }
}
</script>

<style scoped>

</style>
