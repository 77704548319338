<template>
  <div>
    <label>Select Dealers / Subdealers</label>
    <select name="selectDealer" id="selectDealer" v-model="dealerSelected" class="form-control mb-3"
            style="width: 100%;" @change="addDealer($event.target.value)">
      <option :value="null">Select</option>
      <option :value="item.id" v-for="item in dealerList">
        {{ item.fullName }}
      </option>
    </select>
    <div class="table-responsive ">
      <table class="table table-hover table-sm table-bordered">
        <thead>
        <tr>
          <th colspan="5">List of dealers / sub dealers assigned to: {{ user.fullName }}</th>
        </tr>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Country</th>
          <th>Phone</th>
          <th>Options</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in childrenList">
          <td>{{ item.fullName }}</td>
          <td>{{ item.email }}</td>
          <td> {{ $store.getters["geo/country"](item.countryId).alpha3Code }}</td>
          <td>{{ item.phone }}</td>
          <td>
            <b-button @click="removeDealer(item.id)" outline variant="danger" size="sm">Delete</b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {post} from "@/services/api";
import {addDealer, removeDealer} from "@/services/endpoint.json";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "AssignDealers",
  mixins: [show_alert_mixin],
  props: {
    infoDealers: [],
    infoUser: {
      id: null,
      name: '',
      lastName: '',
    },
    infoChildren: [],
  },
  computed: {
    dealerList() {
      return this.infoDealers
    },
    user() {
      return this.infoUser
    },
    childrenList() {
      return this.infoChildren
    }
  },
  watch: {
    dealerList: {
      immediate: true,
      handler() {
      }
    },
    user: {
      immediate: true,
      handler() {
      }
    },
    childrenList: {
      immediate: true,
      handler() {
      }
    }
  },
  data: () => ({
    dealerSelected: null,
  }),

  methods: {
    async addDealer(id) {
      const selecteduser = this.dealerList.filter(x => x.id == id)[0]
      if (selecteduser.network.userParent === null) {
        this.$swal({
          title: 'Do you want to assign the dealer?',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await post(addDealer, {parent: this.user.id, children: id}, true)
            this.$emit('loadMainData')
          }
        });
      } else {
        this.showAlertNotification('The dealer already has a Distributor assigned', 'error')
      }
    },
    removeDealer(id) {
      this.$swal({
        title: 'Do you want to remove the dealer?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(removeDealer, {parent: this.user.id, children: id}, true)
          this.$emit('loadMainData')
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
