import { render, staticRenderFns } from "./CreditLimit.vue?vue&type=template&id=20a759f9&scoped=true&"
import script from "./CreditLimit.vue?vue&type=script&lang=js&"
export * from "./CreditLimit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a759f9",
  null
  
)

export default component.exports