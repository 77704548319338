<template>
  <div>
    <div class="row">
      <div class="col">
        <h6>MARKET FACTOR: {{ countryObj.marketFactor }}</h6>
        <h6>COUNTRY PRICE: {{ countryObj.currencyPrice.toFixed(4) }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h6>CURRENT STATUS SWITCH: {{ this.showSwitch ? "VISIBLE" : "NOT VISIBLE" }}</h6>
        <b-form-select v-model="showSwitch" class="mb-3">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option value="true">VISIBLE</b-form-select-option>
          <b-form-select-option value="false">NOT VISIBLE</b-form-select-option>
        </b-form-select>
      </div>
      <div class="col">
        <h6>CURRENCY DISPLAY: {{ this.showLocal ? "LOCAL" : "TVS" }}</h6>
        <b-form-select v-model="showLocal" class="mb-3">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option value="true">LOCAL</b-form-select-option>
          <b-form-select-option value="false">TVS</b-form-select-option>
        </b-form-select>
      </div>

    </div>
    <div class="row">
      <div class="col text-center">
        <b-button @click="save" class="btn-primary" style="width: 10vw">UPDATE INFORMATION</b-button>
        <p class="mt-2">The update will be seen when the client login again</p>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "SoftwarePricing",
  mixins: [show_alert_mixin],
  mounted() {
    this.id = this.$route.params.id
    this.getStatus()
  },
  props: {
    countryObj: {
      default: null
    }
  },
  data: () => ({
    showLocal: false,
    showSwitch: true,
    id: null
  }),
  methods: {
    async getStatus() {
      const {data} = await get(`user/user-show-currency/${this.id}`, null, true)
      this.showLocal = (!!data) ? data.showLocal : false
      this.showSwitch = (!!data) ? data.showSwitch : true
    },

    async save() {
      await post('user/user-show-currency-save', {
        userId: this.id,
        showLocal: this.showLocal,
        showSwitch: this.showSwitch
      }, true)
      this.getStatus().then()
      this.showAlertNotification("Information updated")
    },
  }
}
</script>

<style scoped>

</style>
