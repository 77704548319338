<template>
  <b-row>
    <b-col>
      <h6>Select for which controle module the user is able to order Software</h6>
      <h6>Current selection:
        {{ (currentOption === 'ECU-COMBO-DSG') ? 'ECU + TCU' : (currentOption === 'DSG') ? 'TCU' : currentOption }}</h6>
      <b-form-group label="Select Tune Type">
        <b-form-radio v-model="option" v-on:change="assignSection" name="some-radios" value="DSG">TCU</b-form-radio>
        <b-form-radio v-model="option" v-on:change="assignSection" name="some-radios" value="ECU-COMBO-DSG">ECU + TCU
        </b-form-radio>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {get, post} from "@/services/api";
import {assignOrderSection, findOrderSection} from "@/services/endpoint.json";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "OrderSoftwareSection",
  mixins: [show_alert_mixin],
  props: ['infoUserId', 'infoCurrentSection'],
  computed: {
    userId() {
      return this.infoUserId
    },
  },
  watch: {
    userId: {
      immediate: true,
      handler() {
      }
    },
  },
  data: () => ({
    option: '',
    currentOption: '',
  }),
  beforeMount() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const {data} = await get(findOrderSection + '/' + this.userId, null, true)
      this.option = data.section
      this.currentOption = data.section
    },

    async assignSection() {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async res => {
        if (res.isConfirmed) {
          await post(assignOrderSection, {id: this.userId, section: this.option}, true)
          this.showAlertNotification('Section assigned correctly')
          this.loadData()
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
