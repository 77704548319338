<template>
  <div style="display:flex; justify-content: center; min-height: 30vw">
    <div class="col-lg-4 col-sm-4 col-md-12 col-xs-12">
      <b-row>
        <b-col>
          <h5 v-if="currentConfiguration !== null">Current Assigned Configuration: {{ currentConfiguration.name }}</h5>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <h5 class="m-0" v-if="priceListSelected">{{ priceListSelected.name }}</h5>
        </b-col>
        <b-col class="text-right">
          <b-button v-if="priceSelectedId != currentAssignedId" variant="danger"
                    @click="assignedGroupList(priceListSelected._id)">ASSIGN PRICE GROUP TO THIS DEALER
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <select name="select_price" id="select_price" v-model="priceSelectedId" class="form-control mb-3"
                  @change="selectedPrice($event.target.value)">
            <option :value="item._id" v-for="item in infoPrices">{{ item.name }}</option>
          </select>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <table class="table table-hover table-sm table-bordered">
            <thead>
            <tr>
              <th>CREDIT</th>
              <th>DISCOUNT</th>
              <th>PRICE</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) of priceListSelected.priceList" :key="index">
              <td>
                <credits-view :amount="item.credit"/>
              </td>
              <td>{{ item.discount }}%</td>
              <td>
                <currency-price-view :amount="item.price"/>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {post} from '@/services/api';
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'AssignPriceList',
  mixins: [show_alert_mixin],
  components: {
    CurrencyPriceView,
    CreditsView
  },
  props: ['infoPrices'],
  data: () => ({
    priceListSelected: {},
    currentConfiguration: null,
    priceSelectedId: null,
    currentAssignedId: null
  }),
  methods: {
    selectedPrice(_id) {
      if (_id) {
        this.priceListSelected = this.infoPrices.find(el => el._id == _id);
      }
    },
    assignedGroupList(_id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Do you want to assign this price group to the dealer?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      })
        .then(async res => {
          if (res.isConfirmed) {
            const userId = this.$route.params.id;
            const listIn = this.infoPrices.find(el => el.usersIn.includes(Number(userId)));
            if (listIn) {
              await post(`credit-price-config/${listIn._id}/remove-user/${userId}`, null, true);
            }
            await post(`credit-price-config/${_id}/add-user/${userId}`, null, true);
            this.showAlertNotification("Information updated correctly")
          }
        });
    },
  },
  mounted() {
    const userId = Number(this.$route.params.id);
    this.priceListSelected = this.infoPrices.find(el => {
      return el.default || el.usersIn.includes(userId);
    });
    if (this.priceListSelected !== undefined) {
      this.priceSelectedId = this.priceListSelected._id;
      this.currentAssignedId = this.priceListSelected._id;
    }
  }
};
</script>

<style scoped>

</style>
