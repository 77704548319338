<template>
  <div>
    <b-row>
      <b-col>
        <ul style="list-style: none">
          <li>Notice:</li>
          <li>1. Total pending software orders (Credit limit amount)</li>
          <li>2. Distributor/Dealer could create unlimited orders If credit limit amount equals 0.</li>
        </ul>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="$userCan(['administrator']) && userInfo.status && ['subdealer','dealer','distributor'].some(el => userInfo.type.includes(el))">
        <label>Allow place order software without balance ?</label>
        <select class="form-control" v-model="allowedOrder.status">
          <option :value="true">Yes</option>
          <option :value="false">No</option>
        </select>
      </b-col>
      <b-col>
        <label>Credit limit amount:</label>
        <input type="number" class="form-control" min="0" v-model="allowedOrder.limit">
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="text-center">
        <b-button class="btn-primary" @click="save()">UPDATE INFORMATION</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CreditLimit",
  mixins: [show_alert_mixin],
  props: {
    allowedOrder: {default: {limit: 0, status: false}, required: true, type: Object},
    userInfo: {default: {}, required: true}
  },
  methods: {
    save() {
      if (this.allowedOrder.limit >= 0) {
        this.sendRequest()
      } else {
        this.showAlertNotification('The credits limit must be greater than zero', 'error')
      }
    },
    sendRequest() {
      post(`user/switch-allow-order-no-balance`, this.allowedOrder, true)
        .then(() => {
          this.showAlertNotification(`<table class="table table-bordered">
               <tr><th>Information updated</th></tr>
               <tr> <td>Is allowed? ${this.allowedOrder.status ? 'Yes' : 'No'}</td></tr>
               <tr><td>Credits limit: ${this.allowedOrder.limit}</td></tr>
            </table>`)
        })
    }
  },
}
</script>

<style scoped>

</style>
