<template>
  <div>
    <b-row>
      <b-col>
        <ul style="list-style: none">
          <li>Notice:</li>
          <li>1. If send burn reports -> Distributor will receive an email confirmation each time their dealers software orders are completed</li>
        </ul>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <select v-model="burns" class="form-control" name="" id="">
              <option :value="true">Send burn reports</option>
              <option :value="false">Not send burn reports</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <button class="btn btn-primary" @click="createConfig">SAVE</button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "BurnReports",
  mixins: [show_alert_mixin],
  data: () => ({
    burns: true,
    overview: 'tvs',
    userId: null,
  }),
  mounted() {
    this.userId = this.$route.params.id
    this.loadData()
  },
  methods: {
    async loadData() {
      const {data} = await get(`user/user-show-currency/${this.userId}`, null, true)
      this.burns = data.sendBurnsReports
      this.overview = data.creditOverview
    },
    async createConfig() {
      await post(`user/config-credit-overview`, {
        id: this.userId,
        overview: this.overview,
        burns: this.burns,
      }, true)
      this.showAlertNotification('Information updated')
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
