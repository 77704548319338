import Vue from 'vue';
import {get, post, put} from '@/services/api';
import {
  companyIndex, resendVerification,
  updateProfile,
  updateRol,
  uploadTerms,
  userCreateCompany,
  userUpdatePassword,
  userView,
} from '../../../services/endpoint.json';
import {getDataStorage} from '@/helpers/localStorage';
import {VueTelInput} from 'vue-tel-input';
import {UserRole} from '@/utils/auth.roles';
import {serializeToDataForm} from "@/utils";
import {mapActions, mapMutations, mapState} from "vuex";
import UpdateRequest from './Updaterequest'
import AssignDealers from './AssignDealers'
import AssignPriceList from './AssignPriceList'
import AgreementDocuments from './AgreementDocuments'
import OrderSoftwareSection from './OrderSoftwareSection'
import UserPermission from './PermissionProcess'
import Obd2ipAccess from './Obd2ipAccess'
import InvoiceConfiguration from './InvoiceConfiguration.vue'
import CreditOverview from "./CreditOverview.vue";
import CreditLimit from "@/views/dashboard/profile-admin/CreditLimit";
import BurnReports from "@/views/dashboard/profile-admin/BurnReports";

import SoftwarePricing from "@/views/dashboard/profile-admin/SoftwarePricing";
import {decrypt} from "@/helpers/encrypt";
import show_alert_mixin from "@/utils/show_alert_mixin";

const fieldCompany = {
  userId: '',
  emailCompany: '',
  nameCompany: '',
  numberCompany: '',
  legalRepresentative: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  continent: '',
  phone: '',
  website: '',
  socialMedia: {
    facebook: '',
    instagram: '',
    twitter: '',
  },
  typeCompany: '',
  zipCode: '',
  otherDsg: '',
  brandOtherDsg: '',
  distributorVag: '',
  elevator: '',
  dinanometer: 0,
  repairDsg: 0,
  sellingHardwareDsg: 0,
  aboutTvs: '',
  promotionalActivities: '',
  futureTvs: '',
  registerSubdealer: '',
};

export default Vue.extend({
  name: 'Profile',
  mixins: [show_alert_mixin],
  components: {
    VueTelInput,
    UpdateRequest,
    AssignDealers,
    AssignPriceList,
    AgreementDocuments,
    OrderSoftwareSection,
    UserPermission,
    Obd2ipAccess,
    SoftwarePricing,
    InvoiceConfiguration,
    CreditLimit,
    CreditOverview,
    BurnReports
  },
  data: () => ({
    user: {
      oldPassword: '',
      passwordOne: '',
      passwordTwo: '',
      id: '',
      country: '',
      email: '',
      lastName: '',
      name: ' ',
      phone: '',
      status: '',
      twoFactor: '',
      avatar: require('@/assets/images/no-photo.svg'),
      userStatus: '',
      validNumber: false,
      controlViewForm: true,
      controlViewBrand: false,
      type: [],
      urlTerm: '',
      term: 0,
      requestUpdate: false,
      countryId: '',
      parent: '',
      allowedOrderNoBalance: {
        status: false,
        limit: 0
      }
    },
    userTerms: 0,
    company: fieldCompany,
    dealerList: [],
    childrenList: [],
    childrenIdList: [],
    dealerSelected: null,
    requestUpdate: false,
    showPriceListConfiguration: false,
    priceList: [],
    priceListSelected: [],
    newCompany: null,
    userId: null,
    currentRole: null,
    rolSelected: null,
    roleListAdmin: [],
    roleListClient: [],
    responseCompany: null,
    permissionButton: {
      showValidationAccountButton: false,
      showButtonUpdateCompany: false,
      showMessageHaveRequest: false,
      resendEmail: false,
      showMessageInvoice: false
    },
    showPage: false,
    showButtonUpdateCompany: true,
    showFormUpdatePersonal: false,
    countryObj: null,
    sessionRol: null,
    invoiceCustomInformation: {
      companyName: {status: true, value: null},
      vatNumber: {status: false, value: null},
      attentionTo: {status: true, value: null},
      address1: {status: true, value: null},
      address2: {status: true, value: null},
      city: {status: true, value: null},
      zipCode: {status: true, value: null},
      country: {status: true, value: null}
    }
  }),
  computed: {
    ...mapState('buySale', ['information'])
  },
  created() {
    this.userId = this.$route.params.id;
    this.loadAllData().then()
  },
  mounted() {
    const storageRol = decrypt(localStorage.getItem('admin-type'))
    this.sessionRol = JSON.parse(storageRol)
    this.sockets.subscribe('profileRequestUpdate', (data) => {
      window.location.reload()
    });
  },
  destroyed() {
    this.sockets.unsubscribe('profileRequestUpdate')
  },
  methods: {
    ...mapActions('buySale', ['loadInformation']),
    ...mapMutations(['loaderManager']),
    showUpdatePersonal() {
      this.showFormUpdatePersonal = !this.showFormUpdatePersonal
    },
    setCountryAndPhone(value, object) {
      if (value !== '') {
        this.phone = value;
      } else {
        this.prone = null;
      }
      this.validNumber = typeof object.valid === 'boolean' && object.valid;
      if (object.country !== undefined) {
        this.country = object.country.name;
      }
    },
    async getData() {
      this.loadInformation().then();
      let {data} = await get(userView, this.userId, true);
      if (Number(data.statusCode) === Number(200)) {
        this.countryObj = data.message.countryObj
        this.user = data.message;
        this.userTerms = data.message.term;
        const rol = data.message.type
        this.dealerList = data.message.users;
        this.childrenList = data.message.children;
        this.showPriceListConfiguration = this.information.haveChildren
        this.currentRole = rol[0];
        this.rolSelected = rol[0];
        this.getRoles()
        this.validateIsParent()
        if (this.childrenList.length) {
          this.childrenIdList = this.childrenList.map(x => x.id)
        }
      } else {
        this.$router.push({name: 'dashboard'}).catch()
      }
    },
    async getDataCompany() {
      const {data} = await get(companyIndex, this.userId, true)
      if (Number(data.statusCode) === Number(200) && data.message !== undefined) {
        this.responseCompany = data.message.company
        if (data.message.company !== null) {
          this.company = data.message.company

          this.setDefaultInvoiceInformation(this.company)
        }
        if (data.message.companyUpdate !== null) {
          this.newCompany = data.message.companyUpdate
        }
      }
    },
    async setDefaultInvoiceInformation(companyInfo) {
      const {data} = await get(`invoice-numeration/get-custom-information?id=${this.userId}`, null, true)
      if (data) {
        this.invoiceCustomInformation.companyName = data.information.companyName
        this.invoiceCustomInformation.attentionTo = data.information.attentionTo
        this.invoiceCustomInformation.vatNumber = data.information.vatNumber
        this.invoiceCustomInformation.address1 = data.information.address1
        this.invoiceCustomInformation.address2 = data.information.address2
        this.invoiceCustomInformation.city = data.information.city
        this.invoiceCustomInformation.zipCode = data.information.zipCode
        this.invoiceCustomInformation.country = data.information.country
      } else {
        this.invoiceCustomInformation.companyName.value = companyInfo.nameCompany
        this.invoiceCustomInformation.attentionTo.value = companyInfo.legalRepresentative
        this.invoiceCustomInformation.address1.value = companyInfo.address
        this.invoiceCustomInformation.address2.value = companyInfo.address2
        this.invoiceCustomInformation.city.value = companyInfo.city
        this.invoiceCustomInformation.zipCode.value = companyInfo.zipCode
        this.invoiceCustomInformation.country.value = companyInfo.country
      }
    },
    canValidateAccount() {
      if (this.user.type.includes('distributor') || this.user.type.includes('dealer')) {
        this.permissionButton.showValidationAccountButton = (this.$userCan(['administrator']) &&
          this.responseCompany !== null &&
          this.userTerms === 0 && !this.user.type.includes('administrator'))
      } else if (this.user.type.includes('subdealer')) {
        this.permissionButton.showValidationAccountButton = (this.$userCan(['administrator']) &&
          this.responseCompany !== null && this.userTerms === 0 && !this.user.type.includes('administrator'))
      } else if (this.user.type.includes('support') || this.user.type.includes('calibrator')) {
        this.permissionButton.showValidationAccountButton = (this.userTerms === 0)
      }
    },
    canResendEmail() {
      if (this.user.type.includes('distributor') || this.user.type.includes('dealer')) {
        this.permissionButton.resendEmail = (this.responseCompany === null)
      } else if (this.user.type.includes('subdealer')) {
        this.permissionButton.resendEmail = (this.responseCompany === null)
      } else if (this.user.type.includes('support') || this.user.type.includes('calibrator')) {
        this.permissionButton.resendEmail = (this.userTerms === 0)
      }
    },
    async loadAllData() {
      this.loaderManager(true)
      await this.getData().then()
      await this.getDataCompany().then();
      await this.getPriceList().then();
      this.canValidateAccount();
      this.canResendEmail()
      this.showPage = true
      this.loaderManager(false)
    },
    async update() {
      if (this.user.name !== "" && this.user.lastName && this.user.phone !== "" && this.user.email !== "" && this.user.email !== undefined) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'question',
          title: 'Are you sure?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async (value) => {
          if (value.isConfirmed) {
            const {data} = await put(updateProfile + this.userId, {
              name: this.user.name,
              lastName: this.user.lastName,
              email: this.user.email.toLowerCase(),
              phone: this.user.phone,
              twoFactor: this.user.twoFactor,
            }, true);
            if (data.statusCode === 200) {
              this.showAlertNotification('Information updated correctly')
              this.showFormUpdatePersonal = false
            } else if (data.statusCode === 406) {
              this.showAlertNotification(`Email ${this.user.email.toLowerCase()}  is already registered`, 'error')
            }
          }
        })
      } else {
        this.showAlertNotification('All fields are required', 'error')
      }
    },
    async updateCompany() {
      this.company.userId = this.userId;
      this.company.other = true;
      this.company.role = localStorage.getItem('admin-type')
      this.company.login = (!this.sessionRol.includes('administrator'));
      const {data} = await post(userCreateCompany, this.company, true);
      this.loadAllData()
      let msg = "Process done correctly. The information is being validated.";
      if (this.newCompany !== null) {
        msg = "You had a previous request. The request was updated and is being validated"
      }
      if (this.$userCan('administrator')) {
        msg = 'Process done correctly'
      }
      if (Number(data.statusCode) === Number(200)) {
        this.showAlertNotification(msg)
      } else {
        this.showAlertNotification('Error', 'error')
      }
    },
    validate() {
      return this.name != null && this.lastName != null && this.country != null &&
        this.email != null && this.phone != null && this.status != null && this.twoFactor != null;
    },
    validateObject(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === '') {
          return false;
        }
      }
      return true;
    },
    validateFormPassword() {
      return this.passwordOne !== '' && this.passwordTwo !== '' && this.passwordOne === this.passwordTwo;
    },
    async updateFormpassword() {
      if (this.validateFormPassword()) {
        const userId = getDataStorage('admin-id');
        await put(userUpdatePassword + userId, {
          password: this.passwordOne,
        }, true);
        this.clearData();
        this.showAlertNotification('Process Success')
      } else {
        this.showAlertNotification('Password are different', 'error')
      }
    },
    async updateRole() {
      if (this.rolSelected !== null) {
        if (Number(this.user.status) === Number(1)) {
          this.$swal({
            showConfirmButton: true,
            showCancelButton: true,
            title: 'Are you sure? New role: ' + this.rolSelected,
            confirmButtonColor: "#d14343",
            cancelButtonColor: "#000000",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          })
            .then(async (result) => {
              if (result.isConfirmed) {
                const {data} = await put(updateRol + '/' + this.user.id, {role: JSON.stringify([this.rolSelected])}, true);
                if (Number(data.statusCode) === Number(200)) {
                  this.showAlertNotification('Information updated correctly')
                  this.getData().then()
                } else if (Number(data.statusCode) === Number(300)) {
                  this.showAlertNotification('The dealer has an assigned distributor. ' + data.message, 'error')
                }
              }
            })
        } else {
          this.showAlertNotification('User must be active', 'error')
        }
      } else {
        this.showAlertNotification('Please Select Role', 'error')
      }
    },
    async processFile(e) {
      const type = localStorage.getItem('admin-type')
      const obj = {
        id: this.userId,
        file: e.target.files[0],
        type: type
      }
      const postData = serializeToDataForm(obj)
      await post(uploadTerms, postData, true)
      this.showAlertNotification('File Uploaded')
      await this.getData();
    },
    async getPriceList() {
      this.priceList = [];
      const {data} = await get(`credit-price-config/index/${this.userId}`, null, true)
      this.priceList = data.list.sort((a, b) => a.default === b.default ? 0 : a.default ? 1 : -1)
    },
    getRoles() {
      const temp = Object.values(UserRole)
      this.roleListClient = temp.slice(0, 3).map(el => (
        {text: el, value: el, disabled: this.user.children.length && el == 'subdealer'}
      ))
      this.roleListAdmin = temp.slice(4, 6).map(el => (
        {text: el, value: el, disabled: this.user.hasMovements}
      ))
    },
    async updateTerms() {
      let msg = '<p>The user will be active on the platform</p>  <br> <b style="color: #d14343">Make sure the currency factor is correct!</b>';
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        html: msg,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await post('user/update-term', {id: this.user.id}, true)
            this.showAlertNotification('Process Success')
            await this.$router.push({name: 'user'})
          }
        });
    },
    async resendEmail() {
      if (this.userId !== '') {
        const {data} = await post(resendVerification, {
          type: 'email',
          id: this.userId,
        });
        if (Number(data.statusCode) === 200) {
          this.showAlertNotification('Email sent')
        }
      }
    },
    validateIsParent() {
      const containChildren = this.childrenList.find(x => Number(x.id) === Number(this.userId))
      return containChildren !== undefined
    },
  },
});
