<template>
  <div>
    <b-row v-if="!exist">
      <b-col offset="4" lg="4">
        <validation-observer v-slot="{handleSubmit}">
          <form @submit.prevent="handleSubmit(save)">
            <validation-provider name="password" rules="required|min:6" v-slot="{errors}">
              <b-form-group>
                <label>Password</label>
                <b-form-input v-model="password" type="password"/>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group class="text-right">
              <b-button type="submit" variant="dark">Save and grant access</b-button>
            </b-form-group>
          </form>
        </validation-observer>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col offset="4" lg="4">
        <b-alert show variant="danger" class="text-center">
          <h3 class="text-white">This user has access to Obd2ip.com</h3>
        </b-alert>
        <b-button variant="dark" @click="removeAccess" block>
          <h5 class="text-white m-0">Remove access</h5>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {post, get} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "Obd2ipAccess",
  mixins: [show_alert_mixin],
  props: {
    userInfo: {default: {}, required: true}
  },
  data: () => ({
    password: null,
    exist: false,
  }),
  methods: {
    getCurrent() {
      get(`user/get-user-obd2ip/${this.userInfo.id}`, null, true).then(({data}) => {
        this.exist = data;
      })
    },
    save() {
      post(`user/add-user-obd2ip/${this.userInfo.id}`, {password: this.password}, true)
        .then(() => {
          this.exist = true;
          this.showAlertNotification("Success")
        })
    },
    removeAccess() {
      this.$swal({
        icon: 'question',
        title: 'Are you sure?',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000',
      }).then(value => {
        if (value.isConfirmed) {
          post(`user/rm-user-obd2ip/${this.userInfo.id}`, null, true)
            .then(() => this.exist = false)
        }
      })
    }
  },
  mounted() {
    this.getCurrent()
  }
}
</script>

<style scoped>

</style>
